import type { Shipment } from 'src/types';

import {
  Card,
  Stack,
  Avatar,
  Button,
  useTheme,
  CardHeader,
  CardContent,
  ListItemText,
} from '@mui/material';

type Props = {
  searchQuery: string;
  shipments: Shipment[];
  handleClick: (id: number) => void;
};

export default function ResultList({ searchQuery, shipments, handleClick }: Props) {
  const regExp = new RegExp(searchQuery, 'i');

  const theme = useTheme();
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.success.main,
  ];

  const color = (i: number) => colors[(i - 1) % colors.length];

  const highlight = (text?: string) => {
    const start = text?.match(regExp)?.index;
    if (searchQuery && text && start != null) {
      const stop = start + searchQuery.length;
      const before = text.substring(0, start);
      const match = text.substring(start, stop);
      const after = text.substring(stop);
      return (
        <>
          {before}
          <b
            style={{
              color: theme.palette.text.primary,
              background: theme.palette.primary.light,
              borderRadius: 4,
              padding: 2,
            }}
          >
            {match}
          </b>
          {after}
        </>
      );
    }
    return text || '-';
  };

  return (
    <Card>
      <CardHeader title="Search Results" />
      {shipments.map((s) => (
        <CardContent
          key={s.id}
          component={Button}
          onClick={() => handleClick(s.id)}
          sx={{ mb: 2, width: '100%' }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              color={color(s.id)}
              sx={{ fontSize: 14, width: 72, height: 72, background: color(s.id) }}
            >
              {' '}
              #{highlight(`${s.id}`)}{' '}
            </Avatar>
            <ListItemText primary="Order" secondary={highlight(`${s.orderNumber}`)} />
            <ListItemText primary="Master BOL" secondary={highlight(s.masterBolNumber)} />
            <ListItemText primary="House BOL" secondary={highlight(s.houseBolNumber)} />
            <ListItemText
              primary="Primary Container #"
              secondary={highlight(s.primaryContainerNumber)}
            />
            <ListItemText
              primary="Secondary Container #"
              secondary={highlight(s.secondaryContainerNumber)}
            />
            <ListItemText
              primary="Primary Chassis Number"
              secondary={highlight(s.primaryChassisNumber)}
            />
            <ListItemText
              primary="Primary Chassis Number"
              secondary={highlight(s.secondaryChassisNumber)}
            />
          </Stack>
        </CardContent>
      ))}
    </Card>
  );
}

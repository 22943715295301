import type { Shipment, ShipmentCopyDTO } from 'src/types';

import useSWR from 'swr';
import { useMemo } from 'react';

import axios, { endpoints, getFetcher, postFetcher } from 'src/lib/axios';

import { isFilterEmpty } from './util';

const URL = endpoints.shipments;

export function useListShipments(filter: any, sort?: any, pagination?: any, relations?: any) {
  const request: any = !isFilterEmpty(filter) && [
    URL,
    {
      params: {
        filter,
        sort,
        pagination,
        relations,
      },
    },
  ];
  const { data, isLoading, error, isValidating, mutate } = useSWR(request, getFetcher);

  const memoizedValue = useMemo(
    () => ({
      shipments: (data || []) as Shipment[],
      mutate,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useSearchShipments(filter?: any, sort?: any, pagination?: any, relations?: any) {
  const request: any = [
    `${URL}/search`,
    {
      filter,
      sort,
      pagination,
      relations,
    },
  ];
  const {
    data = { results: [], count: 0 },
    isLoading,
    error,
    isValidating,
    mutate,
  } = useSWR(request, postFetcher);

  const memoizedValue = useMemo(
    () => ({
      results: data.results as Shipment[],
      count: data.count as number,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data.results.length,
      mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}

export function useGetShipment(id: any) {
  const request: any = id && `${URL}/${id}`;
  const { data, isLoading, error, isValidating } = useSWR(request, getFetcher);

  const memoizedValue = useMemo(
    () => ({
      shipment: data as Shipment,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && data,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function fetchShipment(id: number) {
  if (!id) {
    throw new Error('Invalid shipment ID');
  }

  try {
    const response = await axios.get(`${URL}/with-relations/${id}`, {
      headers: {
        Accept: 'application/json',
      },
    });
    const { data } = response;
    return data as Shipment;
  } catch (error) {
    console.error('Failed to fetch shipment', error);
    return null;
  }
}

export function createShipment(shipment: Shipment) {
  return axios.post(URL, shipment);
}

export function updateShipment(shipment: Shipment) {
  return axios.put(`${URL}/${shipment.id}`, shipment);
}

export function saveShipment(shipment: Shipment) {
  return shipment.id ? updateShipment(shipment) : createShipment(shipment);
}

export function copyShipment(dtos: ShipmentCopyDTO[]) {
  return axios.post(`${URL}/copy`, dtos);
}

export function importShipments(fileData: any) {
  const formData = new FormData();
  formData.append("file", fileData);

  return axios.post(`${URL}/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
import type { Group, ResourceAccess } from 'src/types';

import { useSetState } from 'minimal-shared/hooks';
import { useMemo, useEffect, useCallback } from 'react';

import { Access } from 'src/types';
import axios, { endpoints } from 'src/lib/axios';

import { JWT_STORAGE_KEY } from './constant';
import { AuthContext } from '../auth-context';
import { setSession, isValidToken } from './utils';

import type { AuthState } from '../../types';

// ----------------------------------------------------------------------

/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const { state, setState } = useSetState<AuthState>({ user: null, loading: true });

  const checkUserSession = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(JWT_STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const res = await axios.get(endpoints.auth.me);

        const { user } = res.data;

        setState({ user: { ...user, accessToken }, loading: false });
      } else {
        setState({ user: null, loading: false });
      }
    } catch (error) {
      console.error(error);
      setState({ user: null, loading: false });
    }
  }, [setState]);

  useEffect(() => {
    checkUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const verifyResource = (allowed: ResourceAccess, user: ResourceAccess) =>
    allowed.resource === user.resource;

  const verifyAccess = (allowed: ResourceAccess, user: ResourceAccess) => {
    if (allowed.access === Access.write) {
      return user.access === Access.write;
    }
    if (allowed.access === Access.read) {
      return user.access === Access.read || user.access === Access.write;
    }
    // else if alllowed access not specified, default is allow all
    return true;
  };

  const verifyResourceAccess = useCallback(
    (...allowedPermissions: ResourceAccess[]) => {
      const userPermissions: ResourceAccess[] = state.user?.groups?.flatMap((g: Group) =>
        g.permissions.map(({ resource, access }) => ({ resource, access }))
      );
      if (!allowedPermissions?.length) {
        return true;
      }
      if (!userPermissions?.length) {
        return false;
      }
      const isAllowed = allowedPermissions.some((ap) =>
        userPermissions.some((up) => verifyResource(ap, up) && verifyAccess(ap, up))
      );
      return isAllowed;
    },
    [state.user]
  );

  const memoizedValue = useMemo(
    () => ({
      user: state.user ? { ...state.user, role: state.user?.role ?? 'admin' } : null,
      checkUserSession,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      verifyResourceAccess,
    }),
    [checkUserSession, state.user, status, verifyResourceAccess]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

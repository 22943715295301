type DateTimeStyle = 'full' | 'long' | 'medium' | 'short' | undefined;
type DateType = Date | string | number | null | undefined;

/**
 * default rendering formats
 */
const defaultDateStyle: DateTimeStyle = 'short';
const defaultTimeStyle: DateTimeStyle = 'short';

/**
 * render a date object or string to be displayed on the UI
 * @param d date
 * @returns the rendered date
 */
export const renderDate = (d: DateType, dateStyle = defaultDateStyle) =>
  d ? new Date(d).toLocaleDateString([], { dateStyle }) : '-';

/**
 * render a date object or string with time information to be displayed on the UI
 * @param d date
 * @returns the rendered date with time information
 */
export const renderDateTime = (
  d: DateType,
  dateStyle = defaultDateStyle,
  timeStyle = defaultTimeStyle
) => (d ? new Date(d).toLocaleString([], { dateStyle, timeStyle }) : '-');

export const renderTime = (d: DateType, timeStyle = defaultTimeStyle) =>
  d ? new Date(d).toLocaleTimeString([], { timeStyle }) : '-';

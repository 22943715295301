import type { SWRConfiguration } from 'swr';
import type * as Sentry from '@sentry/react';

import { paths } from 'src/routes/paths';

import packageJson from '../package.json';

// ----------------------------------------------------------------------

export type ConfigValue = {
  appName: string;
  appVersion: string;
  mode: string;
  serverUrl: string;
  assetsDir: string;
  auth: {
    method: 'jwt';
    skip: boolean;
    redirectPath: string;
  };
  mapboxApiKey: string;
  googleMapsApiKey: string;
  s3: {
    bucket: string;
  };
  sentry: Sentry.BrowserOptions;

  /**
   * this should probably be organized
   * into different configurations for
   * different scenarios (display lists, dropdowns, ...)
   */
  swr: SWRConfiguration;
  hazKeywords: string[];
  motive: {
    driver: {
      liveUrl: string;
      historyUrl: string;
      tripDetailUrl: string;
      logDetailUrl: string;
      vehicleUtilizationSummaryUrl: string;
    };
    vehicle: {
      liveUrl: string;
      historyUrl: string;
      tripDetailUrl: string;
      utilizationSummaryUrl: string;
    };
  };
};

// ----------------------------------------------------------------------

export const CONFIG: ConfigValue = {
  appName: 'Minimal UI',
  appVersion: packageJson.version,
  mode: import.meta.env.MODE,
  serverUrl: import.meta.env.VITE_SERVER_URL ?? '',
  assetsDir: import.meta.env.VITE_ASSETS_DIR ?? '',
  auth: {
    method: 'jwt',
    skip: false,
    redirectPath: paths.dashboard.root,
  },
  sentry: {
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
    dsn: import.meta.env.VITE_SENTRY_DSN ?? '',
    environment: import.meta.env.VITE_SENTRY_ENV ?? '',
    release: packageJson.version,
    tracesSampleRate: +import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    tracePropagationTargets: import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS.split(','),
    replaysSessionSampleRate: +import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: +import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  },
  s3: {
    bucket: import.meta.env.VITE_S3_BUCKET ?? '',
  },
  swr: {
    dedupingInterval: import.meta.env.STATIC_LIST_CACHE_DURATION,
  },
  hazKeywords: import.meta.env.VITE_HAZ_KEYWORDS.split(','),
  motive: {
    driver: {
      liveUrl: import.meta.env.VITE_MOTIVE_DRIVER_LIVE_URL ?? '',
      historyUrl: import.meta.env.VITE_MOTIVE_DRIVER_HISTORY_URL ?? '',
      tripDetailUrl: import.meta.env.VITE_MOTIVE_DRIVER_TRIP_DETAIL_URL ?? '',
      logDetailUrl: import.meta.env.VITE_MOTIVE_DRIVER_LOG_DETAIL_URL ?? '',
      vehicleUtilizationSummaryUrl:
        import.meta.env.VITE_MOTIVE_DRIVER_VEHICLE_UTILIZATION_SUMMARY_URL ?? '',
    },
    vehicle: {
      liveUrl: import.meta.env.VITE_MOTIVE_VEHICLE_LIVE_URL ?? '',
      historyUrl: import.meta.env.VITE_MOTIVE_VEHICLE_HISTORY_URL ?? '',
      tripDetailUrl: import.meta.env.VITE_MOTIVE_VEHICLE_TRIP_DETAIL_URL ?? '',
      utilizationSummaryUrl: import.meta.env.VITE_MOTIVE_VEHICLE_UTILIZATION_SUMMARY_URL ?? '',
    },
  },
  mapboxApiKey: import.meta.env.VITE_MAPBOX_API_KEY ?? '',
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY ?? '',
};

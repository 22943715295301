import type { ShipmentDelivery } from 'src/types';

import useSWR from 'swr';
import { useMemo } from 'react';

import axios, { endpoints, getFetcher } from 'src/lib/axios';

const URL = endpoints.shipmentDeliveries;

export function useListShipmentDeliveries(filter: any) {
  const request: any = [
    URL,
    {
      params: {
        filter,
      },
    },
  ];
  const { data, mutate, isLoading, error, isValidating } = useSWR(request, getFetcher);

  const memoizedValue = useMemo(
    () => ({
      shipmentDeliveries: (data || []) as ShipmentDelivery[],
      mutate,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetShipmentDelivery(id: any) {
  const request: any = id && `${URL}/${id}`;
  const { data, isLoading, error, isValidating } = useSWR(request, getFetcher);

  const memoizedValue = useMemo(
    () => ({
      shipmentDelivery: data as ShipmentDelivery,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && data,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function createShipmentDelivery(shipmentDelivery: ShipmentDelivery) {
  return axios.post(URL, shipmentDelivery);
}

export function updateShipmentDelivery(shipmentDelivery: ShipmentDelivery) {
  return axios.put(`${URL}/${shipmentDelivery.id}`, shipmentDelivery);
}

export function saveShipmentDelivery(shipmentDelivery: ShipmentDelivery) {
  return shipmentDelivery.id
    ? updateShipmentDelivery(shipmentDelivery)
    : createShipmentDelivery(shipmentDelivery);
}

export function deleteShipmentDelivery(id: number) {
  return axios.delete(`${URL}/${id}`);
}

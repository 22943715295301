import { lazy } from 'react';
import { Navigate, type RouteObject } from 'react-router';

import { CONFIG } from 'src/global-config';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

const Error404Page = lazy(() => import('src/pages/error/404'));

export const routesSection: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to={CONFIG.auth.redirectPath} replace />,
  },
  ...authRoutes,
  ...dashboardRoutes,
  // No match
  { path: '*', element: <Error404Page /> },
];

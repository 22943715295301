export enum Resource {
  customer_credit_info = 'customer_credit_info',
  shipment = 'shipment',
  shipment_attachments = 'shipment_attachments',
  shipment_bill_to = 'shipment_bill_to',
  shipment_deliveries = 'shipment_deliveries',
  shipment_expenses = 'shipment_expenses',
  shipment_load_info = 'shipment_load_info',
  shipment_final_check = 'shipment_final_check',
  shipment_notes = 'shipment_notes',
  shipment_pickup_return = 'shipment_pickup_return',
  shipment_revenues = 'shipment_revenues',
  shipment_trips = 'shipment_trips',
  shipment_status_completed = 'shipment_status_completed',
  shipment_status_ready_to_bill = 'shipment_status_ready_to_bill',
  shipment_status_billed = 'shipment_status_billed',
  shipment_status_cancelled = 'shipment_status_cancelled',
  shipment_status_pending = 'shipment_status_pending',
}

export enum Access {
  read = 'read',
  write = 'write',
}

export type ResourceAccess = {
  resource: Resource;
  access: Access;
};

export type Permission = ResourceAccess & {
  id: number;
  groupId: string;
};

import 'src/global.css';

import { SWRConfig } from 'swr';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { usePathname } from 'src/routes/hooks';

import { LocalizationProvider } from 'src/locales';
import { themeConfig, ThemeProvider } from 'src/theme';
import { I18nProvider } from 'src/locales/i18n-provider';

import { Snackbar } from 'src/components/snackbar';
import { ProgressBar } from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer, defaultSettings, SettingsProvider } from 'src/components/settings';

import { AuthProvider as JwtAuthProvider } from 'src/auth/context/jwt';

import { ErrorFallback } from './error-fallback';

// ----------------------------------------------------------------------

const AuthProvider = JwtAuthProvider;

// ----------------------------------------------------------------------

type AppProps = {
  children: React.ReactNode;
};

export function App({ children }: AppProps) {
  useScrollToTop();

  return (
    <Sentry.ErrorBoundary showDialog fallback={ErrorFallback}>
      <SWRConfig value={{ revalidateOnFocus: true, revalidateOnReconnect: true }}>
        <I18nProvider>
          <AuthProvider>
            <SettingsProvider defaultSettings={defaultSettings}>
              <LocalizationProvider>
                <ThemeProvider
                  noSsr
                  defaultMode={themeConfig.defaultMode}
                  modeStorageKey={themeConfig.modeStorageKey}
                >
                  <MotionLazy>
                    <Snackbar />
                    <ProgressBar />
                    <SettingsDrawer defaultSettings={defaultSettings} />
                    {children}
                  </MotionLazy>
                </ThemeProvider>
              </LocalizationProvider>
            </SettingsProvider>
          </AuthProvider>
        </I18nProvider>
      </SWRConfig>
    </Sentry.ErrorBoundary>
  );
}

// ----------------------------------------------------------------------

function useScrollToTop() {
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

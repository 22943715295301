import { Alert, AlertTitle } from '@mui/material';

import { CONFIG } from './global-config';

type Props = {
  error: any;
  componentStack: string;
  eventId: string;
  resetError(): void;
};
export function ErrorFallback({ error, componentStack, eventId }: Props) {
  return (
    <Alert color="error">
      <AlertTitle>
        <h1>An error has ocurred... please reload page and contact support !</h1>
      </AlertTitle>
      <h2>
        {error.name} - {error.message}
      </h2>
      {CONFIG.mode === 'development' ? (
        <>
          <h3>{JSON.stringify(eventId)}</h3>
          <pre>{JSON.stringify(error?.cause)}</pre>
          <pre>{JSON.stringify(error?.stack?.split('\n'), null, 2)}</pre>
          <pre>{JSON.stringify(componentStack.split('\n'), null, 2)}</pre>
        </>
      ) : null}
    </Alert>
  );
}

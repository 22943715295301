/**
 * when our UI is loading and we're filtering a parent / child
 * relation where the parent id is still null, we use this helper
 * to instead postpone requesting the children
 *
 * @param filter
 * @returns true if filter is empty, false otherwise
 */
export const isFilterEmpty = (filter: any) =>
  Object.entries(filter || {})
    .map(([k, v]) => {
      if (v === undefined || v === null || v === 0 || v === '') {
        return true;
      }
      if (v instanceof Array && v.filter((item) => !!item).length === 0) {
        return true;
      }
      return false;
    })
    .reduce((a, b) => a && b, true);

import * as Sentry from '@sentry/react';
import { useEffect, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  Outlet,
  matchRoutes,
  useLocation,
  RouterProvider,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router';

import { CONFIG } from 'src/global-config';

import { App } from './app';
import { routesSection } from './routes/sections';
import { ErrorBoundary } from './routes/components';

Sentry.init({
  ...CONFIG.sentry,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
});

// ----------------------------------------------------------------------

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    Component: () => (
      <App>
        <Outlet />
      </App>
    ),
    errorElement: <ErrorBoundary />,
    children: routesSection,
  },
]);

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </StrictMode>
);
